.about-container {
  font-family: Arial, sans-serif;
  background-color: #fbf7ef;
  margin: 5%;
  text-align: center;
}

.mission-statement {
  padding-bottom: 20px;
}

.our-team {
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.team-member {
  display: flex;
  align-items: top;
  margin-top: 20px;
  padding: 100px;
}

/* .avatar {
	width: 200px;
	height: 200px;
	background-color: rgb(229, 212, 180);
	border-radius: 5px;
	/* display: inline-block; */
/* } */

.avatar img {
	width: 100%; /* Ensures the image fits its container */
	height: auto;
	border-radius: 50%; /* Makes the image circular */
	object-fit: cover; /* Ensures the image covers the area without distortion */
  }
 
.name {
  text-align: left;
  font-size: 1.5em;
  padding-top: 0px;
  margin-top: 0px;
}
.desc {
  text-align: left;
}
