/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  color: #333;
  margin: 0;
  padding: 0;
}

/* Hero Section */
.hero {
  background: linear-gradient(to right, #66bb6a, #43a047);
  color: white;
  text-align: center;
  padding: 60px 20px;
  position: relative;
}

.hero h1 {
  font-size: 48px;
  margin-bottom: 20px;
  font-weight: bold;
}

.hero p {
  font-size: 20px;
  margin-bottom: 30px;
}

.hero .bluebutton {
  background-color: #00796b;
  padding: 12px 30px;
  font-size: 18px;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.hero .bluebutton:hover {
  background-color: #004d40;
}

/* Mission Section */
.mission {
  background-color: #f4f4f9;
  padding: 60px 20px;
}

.mission-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.mission-text {
  width: 50%;
}

.mission-text h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.mission-text p {
  font-size: 18px;
  line-height: 1.6;
}

.mission-image img {
  width: 100%;
  border-radius: 8px;
}

/* Vision Section */
.vision {
  padding: 60px 20px;
  background-color: #0c1220;
  color: #f4f4f9;
}

.vision-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.vision-text {
  width: 50%;
}



.vision-text h2 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #fbffa5;
}

.vision-text p {
  font-size: 18px;
  line-height: 1.6;
}

.vision-image img {
  width: 100%;
  height: 800px;
  border-radius: 8px;
}

/* Product Section */
.product {
  padding: 60px 20px;
  background-color: #0c1220;
  color: #ffffff;
}

.product-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.product-description {
  max-width: 700px;
  font-size: 18px;
  margin-bottom: 30px;
}

.product-description h2 {
  color: #fbffa5;
}

.product-image img {
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
}

/* Footer Section */
.footer {
  background-color: #222;
  color: white;
  text-align: center;
  padding: 20px;
}

.footer p {
  margin: 0;
}


.vision {
  padding-right: 10%;
  padding-left: 10%;
}

.product {
  padding-right: 10%;
  padding-left: 10%;
}

/* Media Queries */
@media (max-width: 768px) {
  .mission-content, .vision-content {
    flex-direction: column;
    text-align: center;
  }

  .mission-text, .vision-text {
    width: 100%;
  }
}
