#navbar {
  position: fixed;
  top: 0;
  width: 100%;
  overflow: hidden;
  background-color: rgb(11, 19, 32);
  padding: 0.5rem 10%;
  font-weight: bold;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.headerlogo {
  width: 80px;
}

.headerlogoimage {
  width: 100%;
  height: auto;
  border-radius: 25%;
}

.nav-elements {
  padding-left: 2rem;
  display: flex;
  gap: 1rem;
  margin-right: auto;
}

.nav {
  text-align: center;
}

.nav-item {
  float: right;
  color: #1b6f1a;
  padding: 14px 16px;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
}

.nav-item:hover {
  color: #01bf62;
}

.special-nav-elements {
  display: flex;
  align-items: center;
  gap: 20px;
}

.contribute-button {
  border: 2px solid#1b6f1a;
  margin: 0px 15px;
  padding: 14px 20px;
  border-radius: 30px;
  font-weight: bold;
  text-decoration: none;
  font-size: 20px;
  color: #1b6f1a;
}

.contribute-button:hover {
  color: #01bf62;
}

.shopping-cart-button {
  display: flex;
  align-items: center;
  filter: invert(20%);
}

.shoppingCartIcon {
  width: 50px;
  height: auto;
  filter: invert(80%);
}

.shopping-cart-button:hover {
  filter: invert(0%);
}

.nav-hamburger,
.nav-hamburger-menu {
  display: none;
  margin-right: 16px;
  width: 60px;
  filter: invert(80%);
  margin-left: auto;
  padding: 10px;
}

.nav-hamburger:hover {
  filter: invert(0%);
  cursor: pointer;
}

/*change into hamburger when screen is smaller and show side bar*/
@media screen and (max-width: 1225px) {
  .nav-elements {
    display: none;
  }

  .special-nav-elements {
    display: none;
  }

  .nav-hamburger,
  .nav-hamburger-menu {
    display: block;
  }

  .sidebar {
    display: flex !important;
  }
}

/*sidebar*/

.sidebar {
  position: fixed;
  color: black;
  top: 0px;
  right: -326px;
  /* Start hidden off-screen */
  height: 100vh;
  width: 326px;
  background-color: rgb(255, 255, 255);
  padding-top: 1rem;
  z-index: 1;
  /* Make it appear on top of other elements */
  flex-direction: column;
  transition: transform 0.1s ease-in-out, right 0.1s ease-in-out;
  /* Smooth transition */
}

/* Show the sidebar */
.sidebar-visible {
  right: 0;
  /* Move into view */
}

.sidebar-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 0.5rem 1rem 1.5rem;
  border-bottom: 1px solid rgb(200, 200, 200);
}

.sidebar-header h1 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 0rem;
  margin-top: 5px;
}

.sidebar-close-button {
  border: none;
  background-color: transparent;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  color: #01bf62;
}

.sidebar-closeIcon {
  width: 50px;
  height: auto;
  filter: invert(100%);
}

.sidebar-closeIcon:hover {
  background-color: #fe409d;
}

.sidebar-elements {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: left;
  margin-bottom: 1rem;
  width: 100%;
  border-bottom: 1px solid rgb(200, 200, 200);
}

.sidebar-item {
  color: #01bf62;
  text-decoration: none;
  font-size: 20px;
  padding: 1rem 1rem;
}

.sidebar-item:hover {
  color: #01bf62;
  background-color: rgb(240, 240, 240);
}

.sidebar-special-elements {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-right: auto;
  text-align: left;
  width: 100%;
}

.sidebar-shopping-cart {
  color: #01bf62;
  padding: 1rem 0.5rem;
  text-decoration: none;
  font-size: 25px;
}

.sidebar-shopping-cart:hover {
  color: #01bf62;
  background-color: rgb(240, 240, 240) !important;
}

.sidebar-shoppingCartIcon {
  width: 40px;
  height: auto;
  filter: invert(80%);
  margin-right: 0.5rem;
}

.cart-badge {
  position: absolute;
  top: 1px;
  right: -4px;
  background-color: red;
  color: white;
  border-radius: 100%;
  height: 35%;
  padding: 0px 5px 5px 5px;
  font-size: 12px;
}

/* Navbar active item */
.nav-item.active,
.special-nav-elements .shopping-cart-button.active {
  color: #01bf62;
  font-weight: bold;
}

/* Sidebar active item */
.sidebar-item.active {
  color: #01bf62;
  font-weight: bold;
  background-color: rgb(240, 240, 240);
}
