/* Container for layout */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full height of the viewport */
}

div.mainbody {
  background-color: #fbf7ef;
  padding-top: 5rem;
  flex: 1; /* Allows mainbody to take up available space */
}
