.footer {
  background-color: #0C1320;
  color: #fff;
  text-align: center;
  padding: 10px 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.footer .socials i {
  margin-right: 20px;
  color: #1b6f1a;
  font-size: 1.3rem;
}

.footer .socials i:hover {
  color: #01BF62;
}

.footer .rights {
  color: #01BF62;
}

.footer p {
  margin: 0.5rem 0;
}