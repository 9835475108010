/* Default styles for smaller screens */
.cart-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: space-between;
    padding: 5% 10%;
    color: black;
    flex: 1; /* Fill remaining vertical space */
    min-height: 0; /* Ensure it can shrink below its content size */
}

/* Hide single price below product name and total price beside "+" button on smaller screens */
.price-single, .item-price.show-on-phone{
    display: none;
}

.price-total {
    display: block; /* Show total price on smaller screens */
}

.cart-items {
    width: 100%; /* Take full width on smaller screens */
    margin-bottom: 2rem; /* Space between items and summary */
}

.cart-summary {
    width: 100%; /* Take full width on smaller screens */
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 1rem;
    background-color: white;
}

.cart-summary h2 {
    font-weight: bolder;
    margin-bottom: 2rem;
}

.cart-summary h5 {
    margin-bottom: 1rem;
}

/* Media query for larger screens */
@media screen and (min-width: 854px) {
    .item-price.show-on-phone, .price-single {
        display: block;
    }

    .cart-container {
        flex-direction: row; /* Use row layout for larger screens */
        align-items: flex-start; /* Align items to the start */
        justify-content: space-between; /* Space between items and summary */
    }

    .cart-items {
        width: 60%; /* 60% width on larger screens */
        margin-bottom: 0; /* Remove bottom margin on larger screens */
    }

    .cart-summary {
        width: 30%; /* 30% width on larger screens */
    }

    .price-total {
        display: none; /* Hide total price on larger screens */
    }

    .item-actions {
        margin-right: 0 !important; /* Remove right margin on larger screens */
    }
}
  
.cart-item img {
    display: block; 
    width: 25%;       
    margin-right: 1rem;
    border-radius: 5px;   
}

/* Media query for smaller screens */
@media screen and (max-width: 600px) {
    .cart-item img {
        display: none; /* Hide on screens smaller than 600px */
    }
}

.cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 1rem;
    background-color: white;
}

.item-details {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-info {
    flex: 3;
}

.item-info h5, .item-info h6 {
    margin: 0.5rem 0;
}

.item-price{
    flex: 1;
    text-align: center;
}

.item-actions {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0.5rem;
    margin-right: 1rem;
}

.item-actions button {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.item-actions button:hover {
    background-color: #f0f0f0;
}

.item-remove button {
    background-color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.item-remove button:hover {
    background-color: #f0f0f0;
}

.checkout-button {
    width: 100%;
    padding: 1rem;
    background-color: #1b6f1a;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 1.5rem;
}

.checkout-button:hover {
    opacity: 0.8;
}