.products-body{
    padding: 5% 10%;
    color: black;
}

.products-card {
    height: 100%;
    margin: 0 auto; /* Center the card */
}

.products-card-container{
    display: flex;
    gap: 2rem;
}