.FAQ {
    margin : 0rem 10%;  
    color: black;
}

.FAQ-container{
    display: flex;
    column-gap:  3rem;
}

.FAQ-container-left, .FAQ-container-right {
    border-top: 1px solid #ccc;
    margin-top: -1px; /* To account for the top border overlapping the bottom border */
    width: 650px;
}

/*form a single columns when the page becomes too small*/
@media screen and (max-width: 1300px) {
    .FAQ-container{
        flex-wrap: wrap;
    }
}

.FAQCard {
    border-bottom : 1px solid #ccc;
}

.FAQCard-header {
    display : flex;
    justify-content: space-between;
    align-items: center;
    padding : 1rem 1rem 1rem 0rem;
}

.FAQCard-header:hover {
    cursor: pointer;
}

.FAQCard-body {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.FAQCard-button {
    background-color: transparent;
    border: none;
    font-size: 2rem;
}

/* Reviews Container */
.reviews-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
}

/* Review Card */
.review {
    background-color: white;
    padding: 1rem;
    border-radius: 5px;
    width: 450px;

    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.review-top {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-direction: row;
}

.review-img img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.review-name-and-date {
    display: flex;
    gap : 1rem;
    flex-direction: row;
}

.review-name {
    font-weight: bold;
}

.review-date {
    color: #aaa;
}

.checked {
    color: orange;
}