.bluebutton {
    background-color: #2D9BF0;
    color: white;
    padding: 1px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    width: 10vw;
    height: 3vw;
    bottom: 2vh;
    right: 5vw;
    
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 1.5em;
}
  