
/* General Contact Page Styles */
.contact {
    background-color: #f9f9f9;
    padding: 40px 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .contact-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .contact-title {
    font-weight: bolder;
    font-size: 2.5rem;
    color: #333;
  }
  
  .contact-subtitle {
    color: #666;
    font-size: 1.2rem;
  }
  
  /* Contact Card Container */
  .contact-card-container {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
  }
  
  .contact-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 250px;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .contact-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
  
  .contact-card-logo {
    margin-bottom: 20px;
  }
  
  .contact-card-image {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
  
  .contact-card-info {
    text-align: center;
  }
  
  .contact-card-heading {
    font-size: 1.4rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .contact-card-content {
    font-size: 1.1rem;
    color: #666;
  }
  
  /* Social Media Section */
  .contact-socials {
    text-align: center;
    margin-top: 40px;
  }
  
  .contact-socials h3 {
    font-size: 1.5rem;
    color: #333;
  }
  
  .contact-socials .social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
  }
  
  .social-icon {
    color: #333;
    transition: color 0.3s ease;
  }
  
  .social-icon:hover {
    color: #0073b1; /* Change color on hover */
  }
  
  /* Responsiveness for smaller screens */
  @media (max-width: 768px) {
    .contact-card-container {
      flex-direction: column;
      align-items: center;
    }
  }
  