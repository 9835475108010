.product-modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.product-modal {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 90vh; /* Limit height to viewport height */
  overflow-y: auto; 
  margin-top: 2rem;
  padding: 2rem;
}

.product-modal-content {
  background-color: white;
  padding: 20px;
  width: 40%;
  max-height: 90%; /* Limit content height */
  overflow-y: auto; /* Enable scrolling within the modal */
  border-radius: 8px;
  position: relative;
}

.close {
  color: #aaaaaa;
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.product-modal img {
  padding: 2.5rem 0;
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto; /* Center the image horizontally */
}

.product-modal-content h6 {
  margin-bottom: 10px; /* Adjust spacing below description */
}

.add-to-cart-btn,
.download-btn {
  padding: 10px 20px;
  background-color: #1b6f1a;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
  width: 100%;
}

.add-to-cart-btn:hover, .download-btn:hover {
  opacity: 0.7;
}

.download-btn {
  background-color: #3F4A3C;
}

.quantity-label {
  display: none; /* Hide by default */
}

@media (min-width: 768px) {
  .quantity-label {
    display: inline-block; /* Show on screens 768px and larger */
  }
}

.item-actions {
  margin-right: 0 !important; /* Override the margin-right property from ShoppingCart.css */
}

@media (max-width: 600px) {
  .product-modal-content {
    width: 80%;
  }

  .product-modal img {
    padding: 1rem 0;
    margin-top: 1rem;
  }

  .add-to-cart-btn {
    padding: 5px 10px;
  }
}